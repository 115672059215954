import { useVendor } from './useVendor';
import { trackGenericEvent } from '@/utils/analytics';
import { useAuth, useAuthModal } from '@/contexts';
import { AgoraLinkTypeE, Auth0RolesE } from '@/types/cyclone/models';
import {
  faCalendarCheck,
  faPlus,
  faRightFromBracket,
  faShop,
  faUser
} from '@fortawesome/free-solid-svg-icons';
import { useRouter, usePathname } from 'next/navigation';
import { NavbarItem } from '@/types';

type UseNavbarReturnType = {
  authItems: NavbarItem[];
  dropdownItems: NavbarItem[];
  authProItems: NavbarItem[];
  footerItems: NavbarItem[];
  footerItemsV2: NavbarItem[];
  vendorItems: NavbarItem[];
};

export const useNavbarItems = (): UseNavbarReturnType => {
  const { logIn, logOut, session } = useAuth();
  const { vendor } = useVendor();
  const router = useRouter();
  const path = usePathname();
  const { showAuthModal } = useAuthModal();
  const FLASH_URL = process.env.NEXT_PUBLIC_FLASH_URL || 'https://pro.agora.red';
  const isVendor = session?.role === Auth0RolesE.VENDOR;

  // is activity path
  const isActivitiesPath = path?.includes('/actividades');

  // Will render show if user is not auth
  const authItems: NavbarItem[] = [
    {
      icon: faPlus,
      strongText: true,
      label: 'Creá tu negocio',
      onClick: () => {
        showAuthModal('signup', true);
        trackGenericEvent('Menu Item Clicked', { name: 'create_business' });
      },
      subMenu: [
        {
          icon: faUser,
          label: 'Cliente',
          onClick: () => {
            trackGenericEvent('Menu Item Clicked', { name: 'sign_in_client' });
          }
        },
        {
          icon: faShop,
          label: 'Profesional',
          onClick: () => {
            trackGenericEvent('Menu Item Clicked', { name: 'sign_in_vendor' });
            window.open(FLASH_URL);
          }
        }
      ]
    }
  ];

  // Will render show if user is not auth
  const authProItems: NavbarItem[] = [
    {
      icon: faShop,
      label: 'Ingreso Profesionales',
      onClick: () => logIn({ isVendor: true })
    },
    {
      icon: faUser,
      label: 'Ingreso Clientes',
      onClick: () => logIn({ isVendor: false })
    }
  ];

  // Will render only if user is auth
  const dropdownItems: NavbarItem[] = [
    {
      label: 'Mi Perfil',
      onClick: () => {
        trackGenericEvent('Menu Item Clicked', { name: 'my_profile' });
        isVendor ? window.open(FLASH_URL) : router.push('/perfil');
      },
      icon: faUser,
      show: !isVendor
    },
    {
      label: 'Mi Negocio',
      onClick: () => {
        trackGenericEvent('Menu Item Clicked', { name: 'my_business' });
        window.open(FLASH_URL, '_blank');
      },
      icon: faShop,
      show: isVendor
    },
    {
      label: 'Mis Reservas',
      onClick: () => {
        trackGenericEvent('Menu Item Clicked', { name: 'activities' });
        router.push('/actividades');
      },
      icon: faCalendarCheck
    },
    {
      label: 'Salir',
      onClick: () => {
        trackGenericEvent('Menu Item Clicked', { name: 'sign_out' });
        logOut();
      },
      icon: faRightFromBracket
    }
  ];

  const footerItemsV2: NavbarItem[] = [
    {
      label: 'Funcionalidades',
      onClick: () => {
        trackGenericEvent('Footer About Agora Link Clicked', { link: 'funcionalidades' });

        if (isActivitiesPath) {
          router.push('/funcionalidades?utm_source=agora&utm_medium=link&utm_campaign=actividades');
        } else router.push('/funcionalidades');
      },
      type: 'about'
    },
    {
      label: 'Recursos',
      onClick: () => {
        trackGenericEvent('Footer About Agora Link Clicked', { link: 'sobre-nosotros' });
        if (isActivitiesPath) {
          window.open(
            'https://ayuda.agora.red/es/?utm_source=agora&utm_medium=link&utm_campaign=actividades',
            '_blank'
          );
        } else window.open('https://ayuda.agora.red/es/', '_blank');
      },
      type: 'about'
    },
    {
      label: 'Quiénes somos',
      onClick: () => {
        trackGenericEvent('Footer About Agora Link Clicked', { link: 'sobre-nosotros' });

        if (isActivitiesPath) {
          router.push('/sobre-nosotros?utm_source=agora&utm_medium=link&utm_campaign=actividades');
        } else router.push('/sobre-nosotros');
      },
      type: 'about'
    },
    {
      label: 'Privacidad',
      onClick: () => {
        trackGenericEvent('Footer About Agora Link Clicked', { link: 'politicas-de-privacidad' });

        if (isActivitiesPath) {
          router.push('/politicas-de-privacidad?utm_source=agora&utm_medium=link&utm_campaign=actividades');
        } else router.push('/politicas-de-privacidad');
      },
      type: 'legals'
    },
    {
      label: 'Terminos y condiciones',
      onClick: () => {
        trackGenericEvent('Footer About Agora Link Clicked', { link: 'terminos-y-condiciones-usuarios' });

        if (isActivitiesPath) {
          router.push(
            '/terminos-y-condiciones-usuarios?utm_source=agora&utm_medium=link&utm_campaign=actividades'
          );
        } else router.push('/terminos-y-condiciones-usuarios');
      },
      type: 'legals'
    }
  ];

  const footerItems: NavbarItem[] = [
    {
      label: 'Nosotros',
      onClick: () => {
        trackGenericEvent('Footer About Agora Link Clicked', { link: 'nosotros' });
        router.push('/nosotros');
      },
      type: 'about'
    },
    {
      label: 'Privacidad',
      onClick: () => {
        trackGenericEvent('Footer About Agora Link Clicked', { link: 'politicas-de-privacidad' });
        router.push('/politicas-de-privacidad');
      },
      type: 'about'
    },
    {
      label: 'Terminos y condiciones',
      onClick: () => {
        trackGenericEvent('Footer About Agora Link Clicked', { link: 'terminos-y-condiciones-usuarios' });
        router.push('/terminos-y-condiciones-usuarios');
      },
      type: 'about'
    },
    {
      label: 'Preguntas frecuentes',
      onClick: () => {
        trackGenericEvent('Footer Category Link Clicked', { category: 'vendor', link: 'faq' });
        router.push('/faq');
      },
      type: 'vendor'
    }
  ];

  const vendorItems: NavbarItem[] = [
    {
      label: 'Principal',
      onClick: () => {
        trackGenericEvent('Navbar Storefront Link Clicked', { link: 'Vendor Landing Page' });
        router.push(`/${vendor?.username}/`);
      }
    },
    {
      label: 'Eventos',
      onClick: () => {
        trackGenericEvent('Navbar Storefront Link Clicked', { link: 'Vendor Events' });
        router.push(`/${vendor?.username}/${AgoraLinkTypeE.EVENT}`);
      }
    },
    {
      label: 'Servicios',
      onClick: () => {
        trackGenericEvent('Navbar Storefront Link Clicked', { link: 'Vendor Services' });
        router.push(`/${vendor?.username}/${AgoraLinkTypeE.SERVICE}`);
      }
    },
    {
      label: 'Planes Mensuales',
      onClick: () => {
        trackGenericEvent('Navbar Storefront Link Clicked', { link: 'Vendor Subscriptions' });
        router.push(`/${vendor?.username}/${AgoraLinkTypeE.SUBSCRIPTION}`);
      }
    },
    {
      label: 'Descargables',
      onClick: () => {
        trackGenericEvent('Navbar Storefront Link Clicked', { link: 'Vendor Digital Content' });
        router.push(`/${vendor?.username}/${AgoraLinkTypeE.DOWNLOADABLE}`);
      }
    },
    {
      label: 'Bancar',
      onClick: () => {
        trackGenericEvent('Navbar Storefront Link Clicked', { link: 'Vendor Bancar' });
        router.push(`/${vendor?.username}/${AgoraLinkTypeE.DONATION}`);
      }
    },
    {
      label: 'Agenda',
      onClick: () => {
        trackGenericEvent('Navbar Storefront Link Clicked', { link: 'Vendor Agenda' });
        router.push(`/${vendor?.username}/${AgoraLinkTypeE.AGENDA}`);
      }
    },
    {
      label: 'Sobre mi',
      onClick: () => {
        trackGenericEvent('Navbar Storefront Link Clicked', { link: 'Vendor About Me' });
        router.push(`/${vendor?.username}/${AgoraLinkTypeE.ABOUT}`);
      }
    }
  ];

  return { authItems, dropdownItems, authProItems, footerItems, vendorItems, footerItemsV2 };
};
