import { useClient } from './useClient';
import { AgoraThemeI } from '@/types/cyclone/models';
import { GetVendorI } from '@/types/cyclone/requests';
import { useQuery } from '@tanstack/react-query';

export const useVendor = (username?: string) => {
  const { client } = useClient();

  const { data: vendor, isLoading } = useQuery(
    ['vendor', username],
    async () => await client<GetVendorI>(`vendors/soft/${username}`),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!username
    }
  );

  const getTheme = (): AgoraThemeI | undefined => {
    if (vendor && vendor.vendor_storefront) {
      return {
        name: vendor.vendor_storefront.template_name,
        colors: {
          title: vendor.vendor_storefront.title_color,
          background: vendor.vendor_storefront.background_color,
          text: vendor.vendor_storefront.text_color,
          button: vendor.vendor_storefront.button_color,
          shadow: vendor.vendor_storefront.shadow_color
        },
        pattern_type: vendor.vendor_storefront?.pattern_type
      };
    } else return undefined;
  };

  return { vendor, vendorTheme: getTheme(), isLoading };
};
